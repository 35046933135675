import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`L’esclata-sang té un capell de 6 a 15 cm de diàmetre, al principi convex amb el marge enrotllat i després esdevé obert, i a vegades, en forma d’embut. És de coloracions taronja rogenc a rogenc fluix amb taques verdoses no gaire grosses i normalment presenta zonacions en forma de cercles concèntrics. Les làmines són primes, atapeïdes, forcades, decurrents i de colors variables de rosat carnós a un taronja rogenc fins i tot color vinós. El peu és cilíndric, atenuat a la base, dur i després buit de color taronja rogenc a vinós amb tons intermedis. Les espores són blanques en massa, ovoïdals, reticulades, amb petites berrugues, i de 7,5-8,5 x 6,5-7,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      